import { TweenMax, Expo, TimelineMax, Elastic } from "gsap/dist/gsap";

// Simple fade In
const fade = {
  fadeBeforeEnter: el => {
    el.style.opacity = 0;
  },
  fadeEnter: (el, done) => {
    TweenMax.to(el, { opacity: 1, duration: 0.3, onComplete: done });
  },
  fadeLeave: (el, done) => {
    TweenMax.to(el, { opacity: 0, duration: 0.3, onComplete: done });
  }
};

// Zoom scale
const zoom = {
  zoomBeforeEnter: el => {
    el.style.transform = "scale(1.5)";
    // el.style.opacity = 0;
  },
  zoomEnter: (el, done) => {
    TweenMax.to(el, {
      scale: 1,
      duration: 0.8,
      ease: Expo.easeOut,
      onComplete: done
    });
  },
  zoomLeave: (el, done) => {
    TweenMax.to(el, { scale: 1.5, duration: 0.8, onComplete: done });
  }
};

// Elastic and fade In
const elastic = {
  elasticBeforeEnter: el => {
    el.style.opacity = 0;
    el.style.transform = "scale(0)";
  },
  elasticEnter: (el, done) => {
    TweenMax.to(el, {
      opacity: 1,
      scale: 1,
      duration: 0.8,
      ease: Elastic.easeOut.config(1, 0.3),
      onComplete: done
    });
  },
  elasticLeave: (el, done) => {
    TweenMax.to(el, { opacity: 0, scale: 0, duration: 0.5, onComplete: done });
  }
};

// Slide and scale in from bottom to top
// Stagger fade and slide in for children with [data-stagger] attribute
const slideScale = {
  slideScaleBeforeEnter: el => {
    el.style.opacity = 0;
    el.style.transform = "translateY(100%)";
  },

  slideScaleEnter: (el, done) => {
    TweenMax.to(el, {
      opacity: 1,
      translateY: 0,
      duration: 0.5,
      ease: Expo.easeOut
    });
    const elements = Array.from(el.querySelectorAll("[data-stagger]"));
    if (elements.length === 0) return;
    TweenMax.from(elements, {
      stagger: 0.2,
      duration: 0.4,
      opacity: 0,
      translateY: "100%",
      ease: Expo.easeOut,
      onComplete: done,
      delay: 0.4
    });
  },

  slideScaleLeave: (el, done) => {
    TweenMax.to(el, {
      opacity: 0,
      translateY: "-100%",
      duration: 0.4,
      ease: Expo.easeInOut,
      onComplete: done
    });
  }
};

// Stagger effect
const stagger = {
  staggerBeforeEnter() {},
  staggerEnter(el, done) {
    const tl = new TimelineMax();
    const staggerElements = el.querySelectorAll("[data-stagger]");
    tl
      // .to(el, { opacity: 1, translateY: 0 })
      .from(staggerElements, {
        stagger: 0.2,
        opacity: 0,
        translateY: 50,
        duration: 0.5,
        ease: Expo.easeOut,
        delay: 0.3
      })
      .then(done);
  },
  staggerLeave(el, done) {
    console.log(el, done);
    console.clear();
    // TweenMax.to(el, { opacity: 0, onComplete: done });
  }
};

export { fade, slideScale, stagger, elastic, zoom };
